.card {
  width: 95%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;
  // margin: 50px;

  &.card--not-full-height {
    height: auto;
  }
}

.card-zasah {
  width: 95%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 50px;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed("colorBackground");
  }
}

// .line {
//   // position: absolute;
//   // width: 1724px;
//   // height: 0px;
//   // left: 91px;
//   // top: 195px;
//   // line-height: 0.1em;
//   // border: 1px solid rgba(0, 0, 0, 0.18);
// }

.card__title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 33px;

  color: #057ac4;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {
  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {
  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}
